var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-material-card',{staticClass:"px-4 py-2",attrs:{"color":"cyan darken-3","icon":"mdi-file-document-multiple-outline"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('dashboardtitleTransactions'))+" ")])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.transactionHeaders,"items":_vm.transactions,"item-key":"transactionId","hide-default-footer":"","single-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t('subTitledashboardtitleTransactions'))+" ")])]},proxy:true},{key:"item.transactionDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.transactionDate,'LL'))+" ")])]}},{key:"item.targetPartnerName",fn:function(ref){
var item = ref.item;
return [(item.transactionSource == 1)?_c('div',[_vm._v(" "+_vm._s(item.targetPartnerName)+" ")]):_vm._e(),(item.transactionSource == 2)?_c('div',[_vm._v(" "+_vm._s(item.originPartnerName)+" ")]):_vm._e()]}},{key:"item.originQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.originQuantity).toFixed(2))+" "+_vm._s(item.unitofMeasureText)+" ")])]}},{key:"item.originRQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.originRQuantity).toFixed(2))+" "+_vm._s(item.unitofMeasureText)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.transactionSource == 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.onEditItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(item.transactionSource == 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.onDeleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(item.transactionSource == 2 && item.transactionType == 21)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.onCreateShipment(item)}}},[_vm._v(" mdi-file-document-outline ")]):_vm._e(),(item.transactionSource == 2 && item.transactionType == 1)?_c('v-icon',{on:{"click":function($event){return _vm.onReceiveItem(item)}}},[_vm._v(" mdi-download ")]):_vm._e(),(item.transactionSource == 2 && (item.transactionType == 12 || item.transactionType == 14))?_c('v-icon',{on:{"click":function($event){return _vm.onReceiveWaste(item)}}},[_vm._v(" mdi-download ")]):_vm._e(),(item.transactionSource == 2 && item.transactionType == 31)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.onAcceptCommitment(item)}}},[_vm._v(" mdi-check-bold ")]):_vm._e(),(item.transactionSource == 2 && item.transactionType == 31)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.onRejectCommitment(item)}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]}}])})],1)],1),_c('confirm-dialog',{attrs:{"dialog":_vm.showConfirmDialog,"title":_vm.confirmTitle,"message":_vm.confirmMessage},on:{"update:cancel":function($event){_vm.showConfirmDialog = false},"update:ok":_vm.onTransactionDelete}}),_c('error-dialog',{attrs:{"dialog-type":"error","dialog":_vm.showErrorDialog,"title":_vm.$t('errorTitleProcess'),"message":_vm.errorMessage},on:{"update:dialog":function($event){_vm.showErrorDialog = false}}}),_c('process-overlay',{attrs:{"processing":_vm.modelProcessing}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }