import axios from 'axios'

export default {
  getMaterialMovement: async function (transactionId) {
    const url = '/Transactions/' + transactionId
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getMaterialMovementRecent: async function () {
    const url = '/Transactions/recent'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getMaterialMovementHistory: async function (queryData) {
    const url = '/Transactions/history'
    const requestData = {
      queryValues: queryData,
    }
    const response = await axios.post(url, requestData, { crossdomain: true })
    return response.data
  },
  createMaterialMovement: async function (transactionData) {
    const url = '/Transactions/sendMovement'
    const requestData = {
      TransactionId: Number(transactionData.TransactionId),
      TransactionType: Number(transactionData.TransactionType),
      TransactionDate: transactionData.TransactionDate,
      RelatedTransactionId: Number(transactionData.RelatedTransactionId),
      OriginPartnerId: Number(transactionData.OriginPartnerId),
      TargetPartnerId: Number(transactionData.TargetPartnerId),
      CommitmentPartnerId: Number(transactionData.CommitmentPartnerId),
      OriginListingId: Number(transactionData.OriginListingId),
      TargetListingId: -1,
      OriginQuantity: Number(transactionData.OriginQuantity),
      UnitofMeasure: Number(transactionData.UnitofMeasure),
      StatusCode: 11,
      Documents: transactionData.documents,
      SourceValueChain: transactionData.valueChain,
    }
    const response = await axios.post(url, requestData, { crossdomain: true })
    return response
  },
  receiveMaterial: async function (transactionData) {
    const url = '/Transactions/receiveMovement'
    const requestData = {
      TransactionId: 0,
      TransactionType: Number(transactionData.TransactionType),
      CompletedDate: transactionData.CompletedDate,
      TransactionDate: transactionData.TransactionDate,
      RelatedTransactionId: Number(transactionData.RelatedTransactionId),
      OriginPartnerId: Number(transactionData.OriginPartnerId),
      TargetPartnerId: Number(transactionData.TargetPartnerId),
      CommitmentPartnerId: Number(transactionData.CommitmentPartnerId),
      OriginListingId: Number(transactionData.OriginListingId),
      OriginProductId: Number(transactionData.OriginProductId),
      TargetListingId: Number(transactionData.TargetListingId),
      TargetProductId: Number(transactionData.TargetProductId),
      OriginQuantity: Number(transactionData.OriginQuantity),
      TargetQuantity: Number(transactionData.TargetQuantity),
      UnitofMeasure: Number(transactionData.UnitofMeasure),
      StatusCode: 20,
      Documents: transactionData.documents,
      SourceValueChain: transactionData.valueChain,
    }
    const response = await axios.post(url, requestData, { crossdomain: true })
    return response
  },
  deleteMaterialMovement: async function (transactionId) {
    const url = '/Transactions/' + transactionId
    const response = await axios.delete(url, { crossdomain: true })
    return response.data
  },
}
