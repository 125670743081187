<template>
  <div>
    <base-material-card
      color="cyan darken-3"
      icon="mdi-file-document-multiple-outline"
      class="px-4 py-2"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-bold">
          {{ $t('dashboardtitleTransactions') }}
        </div>
      </template>
      <v-card-text>
        <v-data-table
          :headers="transactionHeaders"
          :items="transactions"
          item-key="transactionId"
          hide-default-footer
          single-select
          class="elevation-1"
        >
          <template v-slot:top>
            <div class="pb-2">
              {{ $t('subTitledashboardtitleTransactions') }}
            </div>
          </template>
          <template v-slot:item.transactionDate="{ item }">
            <div>
              {{ item.transactionDate | moment('LL') }}
            </div>
          </template>
          <template v-slot:item.targetPartnerName="{ item }">
            <div v-if="item.transactionSource == 1">
              {{ item.targetPartnerName }}
            </div>
            <div v-if="item.transactionSource == 2">
              {{ item.originPartnerName }}
            </div>
          </template>
          <template v-slot:item.originQuantity="{ item }">
            <div>
              {{ Number(item.originQuantity).toFixed(2) }}
              {{ item.unitofMeasureText }}
            </div>
          </template>
          <template v-slot:item.originRQuantity="{ item }">
            <div>
              {{ Number(item.originRQuantity).toFixed(2) }}
              {{ item.unitofMeasureText }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.transactionSource == 1"
              class="mr-2"
              color="primary"
              @click="onEditItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="item.transactionSource == 1"
              class="mr-2"
              color="error"
              @click="onDeleteItem(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              v-if="item.transactionSource == 2 && item.transactionType == 21"
              class="mr-2"
              @click="onCreateShipment(item)"
            >
              mdi-file-document-outline
            </v-icon>
            <v-icon
              v-if="item.transactionSource == 2 && item.transactionType == 1"
              @click="onReceiveItem(item)"
            >
              mdi-download
            </v-icon>
            <v-icon
              v-if="item.transactionSource == 2 && (item.transactionType == 12 || item.transactionType == 14)"
              @click="onReceiveWaste(item)"
            >
              mdi-download
            </v-icon>
            <v-icon
              v-if="item.transactionSource == 2 && item.transactionType == 31"
              class="mr-2"
              color="success"
              @click="onAcceptCommitment(item)"
            >
              mdi-check-bold
            </v-icon>
            <v-icon
              v-if="item.transactionSource == 2 && item.transactionType == 31"
              class="mr-2"
              color="error"
              @click="onRejectCommitment(item)"
            >
              mdi-trash-can
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <confirm-dialog
      :dialog="showConfirmDialog"
      :title="confirmTitle"
      :message="confirmMessage"
      @update:cancel="showConfirmDialog = false"
      @update:ok="onTransactionDelete"
    />
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import transactionAPI from '@/services/transaction'
  export default {
    name: 'TransactionsComponent',
    components: {
      ConfirmDialog: () => import('@/views/components/core/ConfirmDialog'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    data: function () {
      return {
        transactionHeaders: [
          {
            text: this.$t('col-dashboard-TransactionId'),
            align: 'end`',
            value: 'transactionId',
            sortable: true,
            class: 'cyan lighten-4 display-1 font-weight-bold',
            width: '5%',
          },
          {
            text: this.$t('col-dashboard-TransactionDate'),
            align: 'start',
            value: 'transactionDate',
            sortable: true,
            class: 'cyan lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
          {
            text: this.$t('col-dashboard-TransactionType'),
            align: 'start',
            value: 'transactionTypeText',
            sortable: true,
            class: 'cyan lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
          {
            text: this.$t('col-dashboard-TargetPartner'),
            align: 'start',
            value: 'targetPartnerName',
            sortable: true,
            class: 'cyan lighten-4 display-1 font-weight-bold',
            width: '20%',
          },
          {
            text: this.$t('col-dashboard-OriginProduct'),
            align: 'start',
            value: 'originProductName',
            sortable: true,
            class: 'cyan lighten-4 display-1 font-weight-bold',
            width: '25%',
          },
          {
            text: this.$t('col-dashboard-OriginQuantity'),
            align: 'end',
            value: 'originQuantity',
            sortable: true,
            class: 'cyan lighten-4 display-1 font-weight-bold',
            width: '12%',
          },
          {
            text: this.$t('col-dashboard-RContent'),
            align: 'end',
            value: 'originRQuantity',
            sortable: true,
            class: 'cyan lighten-4 display-1 font-weight-bold',
            width: '12%',
          },
          {
            text: this.$t('col-dashboard-actions'),
            align: 'center',
            value: 'actions',
            sortable: false,
            class: 'cyan lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
        ],
        showConfirmDialog: false,
        confirmTitle: this.$t('confirmTransactionDelete'),
        confirmMessage: '',
        deleteTransaction: null,
        showErrorDialog: false,
        errorMessage: '',
        modelProcessing: false,
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        transactions: 'dashboardData.transactions',
      }),
    },
    methods: {
      onEditItem: function (item) {
        this.$router.push('/material/editmovement/U/' + item.transactionId)
      },
      onDeleteItem: function (item) {
        this.confirmTitle = this.$t('confirmTransactionDelete')
        this.confirmMessage = this.$t('messageTransactionId') + item.transactionId + '<br />' + this.$t('messageTransactionType') + item.transactionTypeText + '<br />' + this.$t('messageParner') + item.targetPartnerName
        this.deleteTransaction = item
        this.showConfirmDialog = true
      },
      onCreateShipment: function (item) {
        this.$router.push('/material/completerequest/' + item.transactionId)
      },
      onReceiveItem: function (item) {
        this.$router.push('/material/receiveproduct/' + item.transactionId)
      },
      onReceiveWaste: function (item) {
        this.$router.push('/material/receivewaste/' + item.transactionId)
      },
      onAcceptCommitment: function (item) {
        this.$router.push('/material/acceptcommitment/' + item.transactionId)
      },
      onRejectCommitment: function (item) {
        this.confirmTitle = this.$t('confirmTransactionReject')
        this.confirmMessage = this.$t('messageTransactionId') + item.transactionId + '<br />' + this.$t('messageTransactionType') + item.transactionTypeText + '<br />' + this.$t('messageParner') + item.originPartnerName
        this.deleteTransaction = item
        this.showConfirmDialog = true
      },
      onTransactionDelete: function () {
        this.modelProcessing = true
        this.showConfirmDialog = false
        transactionAPI.deleteMaterialMovement(this.deleteTransaction.transactionId)
          .then(response => {
            if (response != null) {
              switch (response.type) {
                case 1:
                  // error
                  this.errorMessage = response.errorMessage
                  this.modelProcessing = false
                  this.showErrorDialog = true
                  break
                default:
                  this.$store.dispatch('GET_DASHBOARD_DATA')
                    .then(response => {
                      this.modelProcessing = false
                    })
                    .catch(error => {
                      this.showErrorDialog = true
                      this.errorMessage = error.message
                      this.modelProcessing = false
                    })
                  break
              }
            }
          })
          .catch(error => {
            this.showErrorDialog = true
            this.errorMessage = error.message
            this.modelProcessing = false
          })
      },
    },
  }
</script>
